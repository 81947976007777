<template>
  <Data :loader="isLoadingDataFirstTime" :isDashboard="true">
    <div class="title dashboard__title pb-5">
      <v-row align="center" justify="space-between">
        <v-col v-if="!isSelectedOneGroup">
          <h1 class="mb-0">{{ $t('contacts.title') }}</h1>
        </v-col>

        <v-col v-else>
          <h1 class="mb-0">
            {{ selectedGroups[0].name }}
            <v-btn
              elevation="0"
              icon
              small
              dense
              @click.prevent="openModalWithRow('renameGroup', selectedGroups[0])"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              elevation="0"
              icon
              small
              dense
              @click.prevent="openModalWithRow('deleteGroup', selectedGroups[0])"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </h1>
        </v-col>

        <v-col cols="auto">
          <CreateNewContacts
            v-if="!isEmptyContentDisplayed"
            is-toolbar-button
            @importContacts="openModal('importFromFile', [])"
            @createContact="openModal('upsertIdentity', [])"
          />
        </v-col>
      </v-row>
    </div>

    <div class="dashboard-documents__wrapper dashboard-contacts">
      <div v-if="isEmptyContentDisplayed" class="dashboard-contacts__empty-content">
        <div class="empty-content__icon">
          <IcoContactLarge />
        </div>

        <div class="empty-content__message">
          <div class="empty-content__message-head">
            <h2>{{ $t('contacts.no_contact') }}</h2>
          </div>

          <div class="empty-content__message-paragraph">
            <p>{{ $t('contacts.info') }}</p>
          </div>
        </div>

        <CreateNewContacts
          @importContacts="openModal('importFromFile', [])"
          @createContact="openModal('upsertIdentity', [])"
        />
      </div>

      <div v-else class="dashboard-documents__table-wrapper">
        <div
          v-if="selected.length > 0"
          class="dashboard-documents__panel dashboard-documents__desktop-panel"
        >
          <div>{{ $tc('table.checkedItems', selected.length, { count: selected.length }) }}:</div>

          <div class="dashboard-documents__panel-link">
            <span>
              <v-icon class="mr-2">mdi-file-send-outline</v-icon>
              <router-link
                class="dashboard-contacts__panel-link"
                :to="{
                  name: 'createNew',
                  params: { workspace_id: filters.workspace_id },
                  query: { contacts: selectedContactIdsCsv },
                }"
              >
                {{ $t('contacts.send_many') }}
              </router-link>
            </span>

            <span @click="openModalWithCheckedRows('openGroupsModal')">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              <span class="dashboard-contacts__panel-link">{{ $t('groups.edit') }}</span>
            </span>

            <span @click="showBulkDeleteContactDialogMethod(true)">
              <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
              <span class="dashboard-contacts__panel-link">{{ $t('contacts.delete') }}</span>
            </span>
          </div>
        </div>

        <v-row v-else align="center" class="mb-2" no-gutters>
          <v-col cols="12" md="4" class="mb-2 mb-md-0 mr-md-2">
            <v-text-field
              type="text"
              :value="filters.searchQuery"
              :placeholder="$t('placeholder.find_name')"
              prepend-inner-icon="mdi-magnify"
              class="filters__search--input"
              hide-details
              clearable
              outlined
              @input="onSearchQueryChange($event)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-select
              v-model="selectedGroups"
              :items="contactsGroups"
              :placeholder="`${$t('contacts.groups')}`"
              append-icon="mdi-chevron-down"
              menu-props="offset-y"
              class="multi-select"
              item-text="name"
              item-value="key"
              height="54"
              solo
              multiple
              hide-details
              flat
              @change="checkGroupStatus"
            >
              <template v-slot:prepend-item>
                <v-list-item style="height: 38px">
                  <v-list-item-content class="multi-select__select-all">
                    <v-checkbox
                      v-model="checkAll"
                      class="mr-6 d-flex multi-select__select-all"
                      @change="selectAll"
                      :disabled="!contactsGroups.length"
                    />
                    {{ $t('general.all') }}
                  </v-list-item-content>
                </v-list-item>

                <v-divider />
              </template>

              <template v-slot:selection="{ item, index }">
                <div v-if="index === 0" class="text-truncate">{{ item.name }}</div>
                <div v-else class="grey--text text-caption d-md-none pl-1">
                  {{ item.name }} (+{{ selectedGroups.length - 1 }} {{ $t('general.other') }}) ???
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-data-table
          v-if="isMobileDevice"
          v-model="selected"
          :headers="mobileHeaders"
          :items="contactsList"
          :options="options"
          :no-data-text="$t('table.noResults')"
          :no-results-text="$t('table.noResults')"
          :server-items-length="-1"
          :loading="isLoadingTableRows"
          hide-default-footer
          item-key="id"
          show-select
        >
          <template v-slot:item.name="{ item }">
            <v-row class="pl-2 pr-1" align="center" justify="space-between">
              <v-row align="center">
                <div class="mr-3">
                  <v-badge v-if="item.has_aml_verified" avatar overlap>
                    <template v-slot:badge>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on" slot="activator" color="success">
                            <v-icon>mdi-check</v-icon>
                          </v-avatar>
                        </template>

                        <span>{{ $t('aml.verification') }}</span>
                      </v-tooltip>
                    </template>

                    <SAvatar :firstname="item.firstname" :lastname="item.lastname" :size="40" />
                  </v-badge>

                  <SAvatar
                    v-else
                    :firstname="item.firstname"
                    :lastname="item.lastname"
                    :size="40"
                  />
                </div>

                <div
                  class="d-flex flex-column data-table__contract-details text-left"
                  @click="getContractDetails(item)"
                >
                  <span>{{ item.fullname }}</span>
                  <span v-if="item.organization_name" class="subtitle-2 font-weight-light">{{
                    item.organization_name
                  }}</span>
                </div>
              </v-row>

              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link @click="sendDocumentToContact(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-file-send-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      class="font-weight-medium text-decoration-underline text-left py-2"
                    >
                      {{ $t('contacts.send_one') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="hasContactFeatureFlag(item, 'isEditable')"
                    link
                    @click.prevent="openModalWithRow('upsertIdentity', item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      class="font-weight-medium text-decoration-underline text-left py-2"
                    >
                      {{ $t('contacts.edit') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click.prevent="openModalWithRow('openGroupsModal', item)">
                    <v-list-item-icon>
                      <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      class="font-weight-medium text-decoration-underline text-left py-2"
                    >
                      {{ $t('groups.edit') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="item.has_aml_verified"
                    link
                    @click.prevent="openModalWithRow('cancelVerification', item)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-close</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      class="font-weight-medium text-decoration-underline text-left py-2"
                    >
                      {{ $t('verification.cancel') }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider v-if="hasContactFeatureFlag(item, 'isEditable')"></v-divider>

                  <v-list-item
                    v-if="hasContactFeatureFlag(item, 'isEditable')"
                    link
                    @click.prevent="showDeleteContactDialogMethod(true, item)"
                  >
                    <v-list-item-icon>
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content
                      class="font-weight-medium text-decoration-underline text-left py-2"
                    >
                      {{ $t('contacts.delete') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </template>

          <template v-slot:item.group="{ item }">
            <DashboardGroup v-for="group in item.groups" :key="group.id" :title="group.name" />
          </template>

          <template v-slot:item.email="{ item }">
            <span class="data-table__email-mobile body-2">{{ item.email }}</span> <br />
          </template>

          <template v-slot:item.mobile="{ item }">
            <span class="body-2">{{ item.mobile }}</span>
          </template>

          <template v-slot:item.address="{ item }">
            <div class="body-2 d-flex flex-column">
              <span>{{ item.organization_street }}</span>
              <span>{{ item.organization_city }} {{ item.organization_zip }}</span>
            </div>
          </template>

          <template v-slot:footer>
            <v-row class="pa-3" align="center" justify="center">
              <v-col class="col-auto mx-3">
                <v-btn
                  icon
                  color="primary"
                  :disabled="isDisabledLoadPrevious"
                  @click="getPreviousPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-col class="col-auto">
                {{ options.page }}
              </v-col>

              <v-col class="col-auto mx-3">
                <v-btn icon color="primary" :disabled="isDisabledLoadNext" @click="getNextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <v-data-table
          v-else
          v-model="selected"
          :headers="headers"
          :items="contactsList"
          :options="options"
          :no-data-text="$t('table.noResults')"
          :no-results-text="$t('table.noResults')"
          :server-items-length="-1"
          :loading="isLoadingTableRows"
          hide-default-footer
          item-key="id"
          show-select
        >
          <template v-slot:item.name="{ item }">
            <div class="data-table__contract-details" @click="getContractDetails(item)">
              <v-row align="center">
                <VerificationBadge :avatarSize="40" :contact="item" />
                <v-col class="d-flex flex-column data-table__contract-details text-left">
                  <span>{{ item.fullname }}</span>
                  <span v-if="item.organization_name" class="subtitle-2 font-weight-light">{{
                    item.organization_name
                  }}</span>
                </v-col>
              </v-row>
            </div>
          </template>

          <template v-slot:item.group="{ item }">
            <DashboardGroup v-for="group in item.groups" :key="group.id" :title="group.name" />
          </template>

          <template v-slot:item.communication="{ item }">
            <span class="data-table__email body-2">{{ item.email }}</span> <br />
            <span class="body-2">{{ item.mobile }}</span>
          </template>

          <template v-slot:item.address="{ item }">
            <div class="body-2">
              <span>{{ item.organization_street }}</span
              ><br />
              <span>{{ item.organization_city }} {{ item.organization_zip }}</span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="sendDocumentToContact(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-file-send-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="font-weight-medium text-decoration-underline text-left py-2"
                  >
                    {{ $t('contacts.send_one') }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="hasContactFeatureFlag(item, 'isEditable')"
                  link
                  @click.prevent="openModalWithRow('upsertIdentity', item)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="font-weight-medium text-decoration-underline text-left py-2"
                  >
                    {{ $t('contacts.edit') }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link @click.prevent="openModalWithRow('openGroupsModal', item)">
                  <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="font-weight-medium text-decoration-underline text-left py-2"
                  >
                    {{ $t('groups.edit') }}
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="item.has_aml_verified"
                  link
                  @click.prevent="openModalWithRow('cancelVerification', item)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="font-weight-medium text-decoration-underline text-left py-2"
                  >
                    {{ $t('verification.cancel') }}
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="hasContactFeatureFlag(item, 'isEditable')"></v-divider>

                <v-list-item
                  v-if="hasContactFeatureFlag(item, 'isEditable')"
                  link
                  @click.prevent="showDeleteContactDialogMethod(true, item)"
                >
                  <v-list-item-icon>
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    class="font-weight-medium text-decoration-underline text-left py-2"
                  >
                    {{ $t('contacts.delete') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:footer>
            <v-row class="pa-3" align="center" justify="center">
              <v-col class="col-auto mx-3">
                <v-btn
                  icon
                  color="primary"
                  :disabled="isDisabledLoadPrevious"
                  @click="getPreviousPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>

              <v-col class="col-auto">
                {{ options.page }}
              </v-col>

              <v-col class="col-auto mx-3">
                <v-btn icon color="primary" :disabled="isDisabledLoadNext" @click="getNextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <div
          v-if="selected.length > 0"
          class="dashboard-documents__panel dashboard-documents__mobile-panel"
        >
          <div>{{ $tc('table.checkedItems', selected.length, { count: selected.length }) }}:</div>

          <div class="dashboard-documents__panel-link">
            <span>
              <v-icon class="mr-2">mdi-file-send-outline</v-icon>
              <router-link
                class="dashboard-contacts__panel-link"
                :to="{
                  name: 'createNew',
                  params: { workspace_id: filters.workspace_id },
                  query: { contacts: selectedContactIdsCsv },
                }"
              >
                {{ $t('contacts.send_many') }}
              </router-link>
            </span>

            <span @click="openModalWithCheckedRows('openGroupsModal')">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              <span class="dashboard-contacts__panel-link">{{ $t('groups.edit') }}</span>
            </span>

            <span @click="showBulkDeleteContactDialogMethod(true)">
              <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
              <span class="dashboard-contacts__panel-link">{{ $t('contacts.delete') }}</span>
            </span>
          </div>
        </div>
      </div>

      <Modal
        v-if="
          dashboardModal &&
          dashboardModal.contactAction &&
          dashboardModal.contactAction !== 'deleteContacts' &&
          dashboardModal.contactAction !== 'upsertIdentity' &&
          dashboardModal.contactAction !== 'importFromFile'
        "
        :enableClose="true"
        @close="closeModal"
      >
        <div v-if="dashboardModal.contactAction === 'createGroup'">
          <form @submit.prevent="createNewGroup()">
            <v-text-field
              type="text"
              name="groupName"
              v-model="modalEditor.newName"
              :label="$t('general.title')"
              :placeholder="$t('placeholder.name')"
              :error="hasNewGroupError"
              :messages="[newGroupError]"
              outlined
            ></v-text-field>

            <v-btn
              type="submit"
              color="primary"
              :disabled="hasNewGroupError || modalEditor.newName.length < 2"
              x-large
              block
            >
              {{ $t('contacts.group_create') }}
            </v-btn>
          </form>
        </div>

        <div v-if="dashboardModal.contactAction === 'renameGroup'">
          <form @submit.prevent="renameGroup()">
            <Input
              v-model="modalEditor.newName"
              type="text"
              :placeholder="$t('placeholder.name')"
              :label="$t('general.title')"
            />

            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('general.rename') }}
              </Button>
            </div>
          </form>
        </div>

        <div
          v-if="dashboardModal.contactAction === 'deleteGroup'"
          class="dashboard-documents__modal-bulk"
        >
          <div
            class="dashboard-documents__modal-bulk-heading"
            v-html="
              $t('groups.delete_confirmHtml', {
                groupName: dashboardModal.contactsSelected[0].name,
              })
            "
          ></div>

          <Button buttonType="submit" type="secondary" @click="deleteGroup()">
            {{ $t('general.delete') }}
          </Button>
        </div>

        <div
          v-if="dashboardModal.contactAction === 'cancelVerification'"
          class="dashboard-documents__modal-bulk"
        >
          <div class="dashboard-documents__modal-bulk-heading text-center">
            {{ $t('verification.cancel.question') }}
          </div>

          <v-btn
            color="error"
            :loading="cancelingVerification"
            x-large
            block
            @click="cancelContactVerification"
          >
            {{ $t('verification.cancel') }}
          </v-btn>
        </div>
      </Modal>
    </div>

    <SConfirmDialog
      v-model="showDeleteContactDialog.show"
      :message="`${$t('contacts.delete')}`"
      @confirmation-callback="deleteContact"
    />

    <ImportContacts
      v-if="dashboardModal && dashboardModal.contactAction === 'importFromFile'"
      :contacts-groups="contactsGroups"
      @reloadContacts="reloadCurrentData"
      @close="closeModal()"
    />

    <GroupsModal
      v-if="
        dashboardModal &&
        dashboardModal.contactAction &&
        dashboardModal.contactAction === 'openGroupsModal'
      "
      :contacts="dashboardModal.contactsSelected"
      @reloadAfterChangingGroups="reloadDataAndCloseModal"
      @close="closeModal"
    >
    </GroupsModal>

    <Identity
      v-if="dashboardModal && dashboardModal.contactAction === 'upsertIdentity'"
      class="modal-phone-off"
      :isContactEditor="true"
      :modalConfig="{
        contractId: null,
        editedSignIdentity: dashboardModal.contactsSelected.length
          ? dashboardModal.contactsSelected[0]
          : null,
      }"
      @close="closeModal()"
      @upsertIdentity="updateContactFromIdentityModal"
    />
  </Data>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { useContactsStore } from '@/features/contacts/stores/contacts';
import { ContactService } from '@/services/ContactService.js';
import { cancelVerification } from '@contacts/services/verificationService';
import { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';
import Modal from '@/components/Modal.vue';
import Identity from '@/views/create/components/Identity.vue';
import IcoContactLarge from '@/views/dashboard/components/icons/IcoContactLarge';
import GroupsModal from '@/views/dashboard/components/GroupsModal';
import ImportContacts from '@contacts/components/ImportContacts.vue';
import DashboardGroup from '@/views/dashboard/components/DashboardGroup';
import CreateNewContacts from '@/views/dashboard/components/CreateNewContacts';
import SAvatar from '@/common/components/SAvatar';
import VerificationBadge from '@contacts/components/VerificationBadge';
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default defineComponent({
  name: 'Contacts',
  components: {
    SConfirmDialog,
    VerificationBadge,
    SAvatar,
    DashboardGroup,
    GroupsModal,
    ImportContacts,
    CreateNewContacts,
    IcoContactLarge,
    Modal,
    Identity,
  },
  setup() {
    const contactsStore = useContactsStore();

    return {
      contactsStore,
    };
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      isLoadedDataFirstTime: false,
      newGroupError: '',
      isLoadingTableRows: true,
      debounceTimeout: false,
      openedRowId: false,
      filters: {
        workspace_id: this.$route.params.workspace_id,
        groups: this.getActiveGroupsFromUrl(),
        searchQuery: '',
      },
      modalEditor: {
        newName: '',
      },
      cancelingVerification: false,
      showDeleteContactDialog: {
        show: false,
        row: {},
      },
      selected: [],
      checkAll: false,
      contactsGroups: [],
      selectedGroups: [],
      mobileHeaders: [
        {
          text: '',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('contacts.groups'),
          sortable: false,
          value: 'group',
        },
        {
          text: this.$t('general.email_simple'),
          sortable: false,
          value: 'email',
        },
        {
          text: this.$t('general.phone'),
          sortable: false,
          value: 'mobile',
        },
        {
          text: this.$t('company.address'),
          sortable: false,
          value: 'address',
        },
      ],
      headers: [
        {
          text: `${this.$t('general.client_name')} / ${this.$t('general.company')}`,
          align: 'start',
          sortable: false,
          value: 'name',
          width: '30%',
        },
        {
          text: this.$t('contacts.groups'),
          align: 'start',
          sortable: false,
          value: 'group',
          width: '25%',
        },
        {
          text: `${this.$t('general.email_simple')}, ${this.$t('general.phone')}`,
          align: 'start',
          sortable: false,
          value: 'communication',
          width: '20%',
        },
        {
          text: this.$t('company.address'),
          align: 'start',
          sortable: false,
          value: 'address',
          width: '20%',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
          width: '5%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      dashboardModal: 'dashboardModal',
    }),
    contactsList() {
      return this.contactsStore.contactsList;
    },
    isLoadingDataFirstTime() {
      return !this.isLoadedDataFirstTime;
    },
    isDisabledLoadPrevious() {
      return 1 >= this.options?.page;
    },
    isDisabledLoadNext() {
      return 0 !== this.contactsStore.contactsList?.length % this.options?.itemsPerPage;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    hasNewGroupError() {
      return this.newGroupError !== '';
    },
    selectedContactIdsCsv() {
      return this.selected
        ?.map((c) => parseInt(c.contact_id))
        ?.sort((a, b) => a - b)
        ?.join(',');
    },
    selectedGroupsIdsCsv() {
      return this.selectedGroups.map((g) => g.key).join(',');
    },
    isSelectedOneGroup() {
      return 1 === this.selectedGroups.length;
    },
    isEmptyContentDisplayed() {
      return (
        1 === this.options.page &&
        !this.contactsList?.length &&
        !this.filters.searchQuery &&
        (!this.filters.groups.length || this.isSelectedOneGroup)
      );
    },
  },
  watch: {
    dashboardModal: {
      handler: function (value) {
        this.modalEditor.newName =
          !value || value?.contactAction !== 'renameGroup'
            ? ''
            : this.dashboardModal.contactsSelected[0].name;
      },
      deep: true,
    },
    '$route.query.groups': {
      handler: function () {
        const activeGroups = this.getActiveGroupsFromUrl();

        this.updateFilters('groups', activeGroups);
        this.contactsGroups?.forEach((selectOption) => {
          selectOption.isSelected = activeGroups.indexOf(selectOption.key) !== -1;
        });
      },
    },
    'modalEditor.newName': {
      handler: function () {
        let groupExist = false;
        this.contactsGroups.forEach((group) => {
          if (this.modalEditor.newName.toLowerCase() === group.name.toLowerCase()) {
            groupExist = true;
          }
        });

        if (groupExist) {
          this.newGroupError = this.$t('groups.create_error.exist');
        } else {
          this.newGroupError = '';
        }
      },
    },
  },
  mounted() {
    this.resetDashboard();
    this.contactsStore.resetContactsList();
    this.fetchData();
  },
  methods: {
    ...mapActions({
      resetDashboard: 'resetDashboard',
    }),
    fetchData(page = this.options.page, itemsPerPage = this.options.itemsPerPage) {
      this.isLoadingTableRows = true;

      this.contactsStore
        .fetchContactsList({
          workspaceId: this.filters.workspace_id,
          groupsIds: this.filters.groups,
          searchQuery: this.filters.searchQuery,
          pagination: {
            limit: itemsPerPage,
            offset: itemsPerPage * (1 <= page ? page - 1 : 0),
          },
          loadMetadata: true,
        })
        .then((response) => {
          if (0 === response.data?.length && 1 < page) {
            return this.fetchData(page - 1, 10);
          }

          this.options.page = page;

          this.contactsGroups = (response?.meta.groups || []).map((group) => ({
            name: group.name,
            key: group.id,
            isSelected: -1 !== this.filters.groups.indexOf(group.id),
          }));

          this.selectedGroups = this.contactsGroups.filter((group) => group.isSelected);

          this.$store.commit('dashboardSetAvailableGroups', this.contactsGroups);
        })
        .finally(() => {
          this.isLoadedDataFirstTime = true;
          this.isLoadingTableRows = false;
        });
    },
    getPreviousPage() {
      this.fetchData(this.options.page - 1, 10);
    },
    getNextPage() {
      this.fetchData(this.options.page + 1, 10);
    },
    showBulkDeleteContactDialogMethod(show) {
      this.showDeleteContactDialog.show = show;
      this.$store.commit('dashboardOpenModal', {
        contactAction: 'deleteContacts',
        contactsSelected: this.selected,
      });
    },
    showDeleteContactDialogMethod(show, row) {
      this.showDeleteContactDialog.show = show;
      this.$store.commit('dashboardOpenModal', {
        contactAction: 'deleteContacts',
        contactsSelected: [row],
      });
    },
    getContractDetails(item) {
      this.$router.push({
        name: `contactDetail`,
        params: {
          workspace_id: this.$route.params.workspace_id,
          contact_id: item.id,
        },
      });
    },
    sendDocumentToContact(item) {
      this.$router.push({
        name: 'createNew',
        params: {
          workspace_id: this.filters.workspace_id,
        },
        query: {
          contacts: item.contact_id,
        },
      });
    },
    async cancelContactVerification() {
      this.cancelingVerification = true;

      await Promise.all(
        this.dashboardModal.contactsSelected.map(async (contact) => {
          await cancelVerification({
            contactId: contact.id,
            verificationTypes: {
              types: ['bankId', 'document', 'microTransaction', 'payment', 'political'],
            },
          });
        }),
      );

      this.cancelingVerification = false;
      this.reloadDataAndCloseModal();
    },
    reloadCurrentData() {
      this.selected = [];
      this.fetchData();
    },
    onSearchQueryChange(value) {
      this.updateFilters('searchQuery', value);
    },
    updateFilters(type, newValue) {
      if (JSON.stringify(this.filters[type]) == JSON.stringify(newValue)) {
        return;
      }
      this.filters[type] = newValue;
      const debounceDuration = 500;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.options.page = 1;
        this.fetchData();
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = false;
        updateQueryParams(this.filters, this.contactsGroups, this);
      }, debounceDuration);

      function updateQueryParams(filters, contactsGroup, vue) {
        setTimeout(() => {
          const query = {};
          if (filters.groups.length) {
            // avoids NavigationDuplicated for string/integer conversion...
            query.groups =
              1 == filters.groups.length ? filters.groups[0] : filters.groups.join(',');
          }
          if (JSON.stringify(query) == JSON.stringify(vue.$route.query)) {
            return;
          }
          vue.$router.replace({ query });
        }, 500);
      }
    },
    getActiveGroupsFromUrl() {
      if (!this.$route?.query?.groups) {
        return [];
      }

      return String(this.$route.query.groups)
        .split(',')
        .filter((id) => parseInt(id))
        .map((id) => parseInt(id));
    },
    hasContactFeatureFlag(row, flag) {
      return hasFeatureFlag(row, flag);
    },
    openModalWithCheckedRows(action) {
      this.openModal(action, this.selected);
    },
    openModalWithRow(action, row) {
      this.openModal(action, [row]);
    },
    openModal(action, contacts) {
      this.openedRowId = null;
      this.$store.commit('dashboardOpenModal', {
        contactAction: action,
        contactsSelected: contacts.map((row) => ({
          custom_inputs: [],
          ...row,
        })),
      });
    },
    updateContactFromIdentityModal({ updatedIdentity, modalConfig }) {
      const editedContact = modalConfig.editedSignIdentity || {};
      const request = {
        workspace_id: this.filters.workspace_id,
        action: 'upsertContacts',
        contacts: [
          {
            id: editedContact ? editedContact.id : null,
            email: updatedIdentity.email,
            mobile: updatedIdentity.mobile,
            firstname: updatedIdentity.firstname,
            lastname: updatedIdentity.lastname,
            birth_date: updatedIdentity.number,
            organization_name: updatedIdentity.organization_name,
            organization_city: updatedIdentity.organization_city,
            organization_street: updatedIdentity.organization_street,
            organization_zip: updatedIdentity.organization_zip,
            organization_ico: updatedIdentity.organization_ico,
            organization_dic: updatedIdentity.organization_dic,
            signatureFooter: updatedIdentity.signatureFooter,
          },
        ],
        groups: this.selectedGroups.map((g) => ({ id: g.key })),
      };
      ContactService.manageContacts(request).then(
        () => {
          return this.reloadDataAndCloseModal();
        },
        () => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        },
      );
    },
    createNewGroup() {
      ContactService.manageContacts({
        workspace_id: this.filters.workspace_id,
        action: 'upsertGroups',
        groups: [
          {
            name: this.modalEditor.newName,
          },
        ],
      })
        .then(() => {
          this.reloadDataAndCloseModal();
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
    },
    renameGroup() {
      ContactService.manageContacts(
        this.createGroupQuery({
          action: 'upsertGroups',
          workspace_id: this.$route.params.workspace_id,
        }),
      )
        .then(() => {
          this.reloadDataAndCloseModal();
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
    },
    deleteGroup() {
      ContactService.manageContacts(
        this.createGroupQuery({
          action: 'deleteGroups',
          workspace_id: this.$route.params.workspace_id,
        }),
      )
        .then(() => {
          this.filters.groups = [];
          this.reloadDataAndCloseModal();
        })
        .catch(() => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        });
    },
    createGroupQuery(data) {
      return {
        workspace_id: data.workspace_id,
        action: data.action,
        groups: [
          {
            id: this.selectedGroups[0].key,
            name: this.modalEditor.newName,
          },
        ],
      };
    },
    checkGroupStatus() {
      this.checkAll = this.selectedGroups.length === this.contactsGroups.length;
      this.updateFilters('groups', this.selectedGroups);
    },
    selectAll() {
      if (this.checkAll) {
        this.selectedGroups = this.contactsGroups.map((tag) => tag.key);
      } else {
        this.selectedGroups = [];
      }

      this.checkGroupStatus();
    },
    closeModal() {
      this.modalEditor = {
        newName: '',
      };
      this.$store.commit('dashboardCloseModal');
    },
    deleteContact() {
      ContactService.manageContacts({
        workspace_id: this.filters.workspace_id,
        action: 'deleteContacts',
        contacts: this.dashboardModal.contactsSelected,
      }).then(
        () => {
          this.reloadCurrentData();
          this.$notification.success(this.$t('contacts.delete.success'));
        },
        () => {
          this.$notification.error(this.$t('contract.errors.general_save'));
        },
      );
    },
    reloadDataAndCloseModal() {
      this.reloadCurrentData();
      this.closeModal();
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.dashboard-documents__panel-link span) {
  text-decoration: none !important;

  .dashboard-contacts__panel-link {
    text-decoration: underline !important;
  }
}

:deep(.v-data-table__mobile-row__cell) {
  width: 100% !important;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr) {
  @media screen and (min-width: 768px) {
    height: 75px !important;
  }
}

.multi-select {
  border: 1px solid #ced4da;
}

.data-table__contract-details {
  cursor: pointer;
}

.data-table__email {
  display: inline-block;
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-table__email-mobile {
  display: inline-block;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard__title {
  max-width: 100%;

  @include md {
    margin: unset !important;
  }
}

// TODO: UI - complicated version copy-pasted in Documents for managing selected group

.dashboard-contacts__empty-content {
  text-align: center !important;
  border: 1px solid #e0e3e8;
  border-radius: 6px;
  padding: 130px 33%;

  .empty-content__icon {
    margin-bottom: 20px;
  }

  .empty-content__message {
    h2 {
      text-align: center;
      font-weight: unset;
      color: #5f6369;
    }

    p {
      text-align: center;
      color: #848b99;
    }
  }

  @include md {
    padding: 130px 10%;
  }
}
</style>
