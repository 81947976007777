<template>
  <SigniModal :value="true" max-width="40rem" @close="$emit('close')">
    <template v-slot:title>
      {{ $t('contacts.import') }}
    </template>

    <template v-slot:default>
      <v-row align="center" justify="center" no-gutters>
        <v-col class="col-12 pb-3">
          <p class="text-center mb-0">{{ $t('contacts.import.help') }}</p>
        </v-col>

        <v-col class="col-12 pb-3">
          <v-card color="#EEEEEE" class="pa-2" rounded="lg" elevation="0">
            <v-card-text class="py-1 px-0">
              <v-row class="headers-row" align="center" justify="start" no-gutters>
                <v-col
                  v-for="importKey in importKeys"
                  :key="importKey.key"
                  class="col-12 col-sm-6 col-md-4 headers-item text-center"
                  :class="{ 'red--text': importKey.required }"
                >
                  <span class="required">{{ `${importKey.required ? '* ' : ''}` }}</span
                  >{{ importKey.title }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="col-12 py-3">
          <v-row align="center" justify="center">
            <v-col class="col-12 col-sm-8">
              <v-file-input
                v-model="file"
                color="primary"
                :label="$t('contacts.import')"
                prepend-icon="mdi-file-account-outline"
                hide-details
                outlined
                dense
              ></v-file-input>
            </v-col>

            <v-col class="col-12 col-sm-4">
              <v-btn color="primary" :loading="uploading" block @click="uploadContactsResource">{{
                $t('general.upload')
              }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </SigniModal>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { generateAuthHeader } from '@/config/authHeader';
import { internalApiRequest } from '@/shared/services/apiService';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import SigniModal from '@/components/SigniModal.vue';

export default defineComponent({
  name: 'ImportContacts',
  components: {
    SigniModal,
  },
  props: {
    contactsGroups: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const route = instance.proxy.$route;

    // TODO - as translations
    const importKeys = ref([
      { key: 'firstname', title: 'firstname', required: false },
      { key: 'lastname', title: 'lastname', required: false },
      { key: 'email', title: 'email', required: true },
      { key: 'mobile', title: 'mobile', required: false },
      { key: 'birthDate', title: 'birthDate', required: false },
      { key: 'addressCity', title: 'addressCity', required: false },
      { key: 'addressStreet', title: 'addressStreet', required: false },
      { key: 'addressZip', title: 'addressZip', required: false },
      { key: 'company', title: 'company', required: false },
      { key: 'ico', title: 'ico', required: false },
      { key: 'dic', title: 'dic', required: false },
      { key: 'customData', title: 'customData', required: false },
      { key: 'groups', title: 'groups', required: false },
    ]);

    const file = ref(null);
    const uploading = ref(false);

    const workspaceId = computed(() => route?.params?.workspace_id);

    const uploadContactsResource = () => {
      uploading.value = true;

      const formData = new FormData();

      formData.append('file', file.value);

      internalApiRequest({
        method: 'POST',
        path: `api/v2/workspaces/${workspaceId.value}/contacts/import?language=en`,
        data: formData,
        headers: generateAuthHeader({
          authorization: 'Bearer',
          isForm: true,
        }),
      })
        .then((resp) => {
          instance.proxy.$notification.success(
            `${instance.proxy.$t('contacts.import.stats_created')}: ${
              resp?.created
            }, ${instance.proxy.$t('contacts.import.stats_updated')}: ${resp?.updated}`,
          );
          emit('reloadContacts');
          emit('close');
        })
        .catch((err) => {
          instance.proxy.$notification.error(getErrorResponseMessage(err.data));
        })
        .finally(() => {
          uploading.value = false;
        });
    };

    return {
      file,
      uploading,
      importKeys,
      uploadContactsResource,
    };
  },
});
</script>

<style lang="scss" scoped>
.headers-row {
  background-color: #eee;
  border-radius: 8px;
}

.headers-item {
  font-size: 0.8rem;

  & .required {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
